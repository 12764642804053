
import React, { useState, useEffect } from "react";
import { saveAs } from 'file-saver';
import { BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import './App.css'

const ProtectedPgg = () => {
  
    return (
      <Router>
      <div>
      <Link to="/edit/hu"><div className="langSelect">
              Magyar
            </div></Link>
            <Link to="/edit/en"><div className="langSelect">
              Angol
              </div></Link>
  
        <Routes>
          <Route path="/edit/:lang" element={<EditPage />} />
        </Routes>
      </div>
    </Router>
    );
  };
  function EditPage() {
      const [data, setData] = useState({});
      const [editedData, setEditedData] = useState({});
      const {lang} = useParams();
    
      useEffect(() => {
        const fetchData = async () => {
          const result = await axios(`/${lang}.json`);
          setData(result.data);
        };
        fetchData();
      }, [lang]);
    
      const handleEdit = (key, value) => {
        setEditedData({ ...editedData, [key]: value });
      };
      const handleAddRow = () => {
        const newKey = prompt('Új kulcs:');
        if (newKey) {
            setData({ ...data, [newKey]: '' });
            setEditedData({ ...editedData, [newKey]: '' });
        }
    };
    function isEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

    useEffect(() => {
      setData((prevData) => {
        // Ha a prevData és a result.data nem egyezik meg, akkor frissítsd az adatokat
        if (!isEqual(prevData, data)) {
          return data;
        }
        return prevData;
      });
    }, [data]);
      const handleSave = async () => {
        
        const newData = { ...data, ...editedData };
  const response = await fetch('/update.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      data: JSON.stringify(newData),
      lang: lang,
      security: '27257748b4197fa0145729c603f5b2fb2ec80c31'
    }),
  });

  const result = await response.text();

  window.alert(result);

  setData(newData);
  setEditedData({});
      };
    
      return (
            <div id="langbody">
              <div className="header">
          <h2>{lang} szerkesztése</h2>
          <button className="save" onClick={handleAddRow}>Új sor</button>
          <button className="save" onClick={handleSave}>Mentés</button>
          </div>
          <table>
            <tbody>
              {Object.keys(data).map((key) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>
                    <textarea
                      value={editedData[key] !== undefined ? editedData[key] : data[key]}
                      onChange={(e) => handleEdit(key, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
        </div>
      );
    }
  export default ProtectedPgg;