import LoginPage from './Login.js'

function App() {
  
  return (
    <LoginPage/>
  );
}



export default App;
