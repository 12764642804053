import React, { useState } from "react";
import { auth} from "./firebase";
import { signInWithEmailAndPassword} from "firebase/auth";
import ProtectedPgg from "./PageEdit";

const LoginPage = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, username, password);
    setIsAuthenticated(true);
    } catch (err){
      console.error(err);
      setIsAuthenticated(false);
    }
  };


  return (
    <div className="login-container">
      <h2>Bejelentkezés</h2>
      <input
        className="input-field"
        type="text"
        placeholder="Felhasználónév"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        className="input-field"
        type="password"
        placeholder="Jelszó"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="login-button" onClick={signIn}>
        Bejelentkezés
      </button>
    </div>
  );
};

const ProtectedPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div>
      {isAuthenticated ? (
        <ProtectedPgg/>
      ) : (
        <LoginPage setIsAuthenticated={setIsAuthenticated} />
      )}
    </div>
  );
};

export default ProtectedPage;